import React, {useState} from "react";
import FeatureImg from "../components/features/FeatureImg";
import FeatureImgThree from "../components/features/FeatureImgThree";
import FeatureImgTwo from "../components/features/FeatureImgTwo";
import Features from "../components/features/Features";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import AllReview from "../components/testimonial/AllReview";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import image1 from "../logos/photos/11.jpg"
import image2 from "../logos/photos/22.jpg"
import image3 from "../logos/photos/33.jpg"
import image4 from "../logos/photos/44.jpg"
import image5 from "../logos/photos/55.png"
import image6 from "../logos/photos/66.jpg"
import image7 from "../logos/photos/77.jpg"
import image8 from "../logos/photos/88.jpg"
import image9 from "../logos/photos/12.jpg"
import image10 from "../logos/photos/10.jpg"


export default function UserBoard() {

  return (
    <Layout>

     
      <section id='advisory' className="promo-section mt-5 ptb-100" style={{backgroundColor:'#fff2ea'}}>
         <h2 style={{color:'#e95d04',textAlign:'center'}}>User Advisory Board</h2>
         <br/>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
         
              <div class="cards-container">
        <div class="container6">
            <div class="svg-background"></div>
            <img class="profile-img" src={image1}/>
            <div class="text-container">
                <p class="title-text">Michel Bour</p>
                <p class="info-text">FIRE-IN European Project Manager at SAFECLUSTER/ Crisis management trainer/ Teacher at Upper-Rhine University</p>
                <ul class="desc-text">
                <li>	<b>Current Role</b>: Fire Chief, Senior Fire Officer</li>
                <li>	<b>Expertise</b>: Crisis Management Expert, Cross-border disaster relief coordination with German and Swiss Rescue Services (French side)</li>
                <li>	<b>Experience</b>: General Secretary of CTIF (2011-2019)</li>
                <li>	<b>International Work</b>: EU-China cooperation missions (2014-2016)</li>
                 

                </ul>
            </div>
        </div>
        <div class="container6">
        <div class="svg-background"></div>
            <img class="profile-img"  src={image2}/>
            <div class="text-container">
                <p class="title-text">Sergi Alegre</p>
                <p class="info-text">Director General of Airport Regions Council (ARC)</p>
                <ul class="desc-text">
                    <li><b>Current Role</b>: Director General, Airport Regions Council, representing multiple regions</li>
                    <li><b>Public Service</b>: Former Vice-Mayor: El Prat de Llobregat, Metropolitan Government of Barcelona (27 years)</li>
                    <li><b>Expertise</b>: Urban development, community participation</li>
                    <li><b>Experience</b>: Fireman (15 months), gaining firsthand experience in disaster management</li>
                </ul>
            </div>
        </div>

        <div class="container6">
        <div class="svg-background"></div>
            <img class="profile-img"  src={image3}/>
            <div class="text-container">
                <p class="title-text">Jelena Mazaj</p>
                <p class="info-text">Coordinator, Higher Education and Research Unit, CESIE</p>
                <ul class="desc-text">
                    <li><b>Current Role</b>: HE&R Coordinator at CESIE in Italy</li>
                    <li><b>Experience</b>: 15+ years in research, project management, higher education, and civic & social organisations</li>
                    <li><b>Skills</b>:EU policy on SDGs, social media, international relations, coaching, lecturing</li>
                    <li><b>Research</b>: Sustainable innovations, organisational performance, EU R&I networks</li>
                    <li><b>Publications</b>: 10+ scientific papers, 15+ project deliverables</li>
                </ul>
            </div>
        </div>

        <div class="container6">    <div class="svg-background"></div>
            <img class="profile-img"  src={image4}/>
            <div class="text-container">
                <p class="title-text">Nikos Passas</p>
                <p class="info-text">Head of the Independent Department for Civil Protection of the Region of Attica</p>
                <ul class="desc-text">
                <li><b>Current Role</b>: Head of Civil Protection, Region of Attica (since Dec 2021)</li>
                    <li><b>Expertise</b>: Soil/rock mechanics, environmental & crisis management, decision-making tools</li>
                    <li><b>Research</b>: 35 publications, 15 research contracts, 100+ environmental studies ((EC, PHARE/TACIS, ASIA ITC, WORLD BANK)</li>
                    <li><b>Awards</b>: SILVER Best City Award, Gold IoT Award</li>
                </ul>
            </div>
        </div>

        <div class="container6">    <div class="svg-background"></div>
            <img class="profile-img"  src={image5}/>
            <div class="text-container">
                <p class="title-text">Ioannis Kapris</p>
                <p class="info-text">Independent Department for Civil Protection of the Region of Attica (Design Department for Civil Protection)</p>
                <ul class="desc-text">
                <li><b>Current Role</b>: Independent Directorate of Civil Protection, Attica, Greece</li>
                <li><b>Department</b>: Member of the Design Department for Civil Protection for the Attica Region</li>
                    <li><b>Expertise</b>: Environmental management, crisis management, climate change, natural disasters</li>
                    <li><b>Publications</b>: 15 scientific papers</li>
                    <li><b>Awards</b>: SILVER Best City Award, Gold IoT Award</li>
                </ul>
            </div>
        </div>

        <div class="container6">    <div class="svg-background"></div>
            <img class="profile-img"  src={image6}/>
            <div class="text-container">
                <p class="title-text">Areti Plessa</p>
                <p class="info-text">Independent Civil Protection Directorate, Region of Attica (Department of Planning)</p>
                <ul class="desc-text">
                    <li><b>Current Role</b>: Disaster planning and preparedness, Independent Civil Protection Directorate, Attica, Greece</li>
                    <li><b>Experience</b>: Earthquake & tsunami data analysis at the National Observatory of Athens (1997-2019)</li>
                    <li><b>EU & National Projects</b>: Involved in research, mainly on earthquake and tsunami risk</li>
                </ul>
            </div>
        </div>

        <div class="container6">    <div class="svg-background"></div>
            <img class="profile-img"  src={image7}/>
            <div class="text-container">
                <p class="title-text">Maria Papathoma-Köhle</p>
                <p class="info-text">Senior Postdoctoral Researcher, Institute for Mountain Risk Engineering, Univerity of Natural Resources and Life Sciences Vienna, (BOKU)</p>
                <ul class="desc-text">
                <li><b>Current Role</b>: Researcher at academic institutes in Vienna since 2005</li>
                    <li><b>Expertise</b>: Vulnerability assessments of buildings using indicators and curves for tsunamis, floods, debris flows, wildfires; institutional vulnerability and physical resilience</li>
                    <li><b>Publications</b>: Frequent journal contributor and reviewer</li>
                    <li><b>Awards</b>: Elise Richter Postdoctoral Fellowship & others</li>
                </ul>
            </div>
        </div>


        <div class="container6">    <div class="svg-background"></div>
            <img class="profile-img"  src={image8}/>
            <div class="text-container">
                <p class="title-text">David Reinberger</p>
                <p class="info-text">City of Vienna, Chief Executive Office - Organisation and Security Group for Crisis Management and Security</p>
                <ul class="desc-text">
                <li><b>Current Role</b>: Chief Executive Office - Organisation and Security Group for Crisis Management and Security (since 2022)</li>
                    <li><b>Expertise</b>: Worked on the DELPHI-Experiment at CERN (Austrian Academy of Science)</li>
                    <li><b>Public service</b>: Appointee at Vienna Ombuds-office for Environmental Protection, focusing on nuclear issues and technical environmental protection (16 years)</li>
                    <li><b>Responsibilities</b>: CBRN, psycho-social emergency care, and climate change issues</li>
                </ul>
            </div>
        </div>

        <div class="container6">    <div class="svg-background"></div>
            <img class="profile-img"  src={image9} />
            <div class="text-container">
                <p class="title-text">Anna Scolobig </p>
                <p class="info-text">Senior researcher and lecturer - International Institute for Applied Systems Analysis (Vienna, Austria) & University of Geneva (Switzerland)</p>
                <ul class="desc-text">
                <li><b>Current Role</b>: Senior research scholar at IIASA (Vienna, Austria) & University of Geneva</li>
                    <li><b>Expertise</b>: Social, political, and institutional aspects of disaster risk management. effectiveness of practices and policies</li>
                    <li><b>Research focus</b>: Evaluating disaster risk reduction policies, integrating technical, societal, and economic aspects</li>
                    <li><b>Collaboration</b>: Partners with teams/local stakeholders on adaptation, risk mitigation, and early warning systems</li>
                </ul>
            </div>
        </div>

        <div class="container6">    <div class="svg-background"></div>
            <img class="profile-img"  src={image10}/>
            <div class="text-container">
                <p class="title-text">Zoltán Hozbor</p>
                <p class="info-text">Duty Officer at the National Directorate General for Disaster Management </p>
                <ul class="desc-text">
                <li><b>Current Role</b>:	Fire Officer at Hungary’s Disaster Management Command (10 years)</li>
                    <li><b>Expertise</b>: Expert in Control Room operations for Fire & Rescue, early warning, and safety</li>
                    <li><b>Previous Experience</b>: Former Airport Fire & Rescue Officer (10 years, Budapest), various roles in operations, emergency planning, training, QA, PR, and compliance</li>
                
                    <li><b>Leadership</b>: Member of CTIF Airport Commission during airport service</li>
                    <li><b>International Work</b>: FEU national delegate since 2012</li>
                </ul>
            </div>
        </div>
    </div>
              </Col>
            </Row>
            </Container>
          
       <br/>
      </section>
    
      
      <Footer />
    </Layout>
  );
}
