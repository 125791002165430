import React from "react";


export default function AboutApp() {
  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
        <h3 style={{color:'#ef8948'}}>Consortium</h3>
              <p className="mb-4 lh-190">
              To materialise the PANTHEON ambitious goals, a consortium of partners from European and associated countries has been assembled with the purpose of designing and developing a Community-based Digital Ecosystem for Disaster Resilience based on Smart City Digital Twin (SCDT). The multinational and multidisciplinary participants come from: 
              </p>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-5">
              
              
              <ul className="list-unstyled">
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                    <div >
                        <img src="assets/img/greece.svg" style={{border:'2px solid #292929',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{ marginLeft:'20px'}}>
                      <span style={{fontWeight:'600',color:'#ef8948'}}> Greece:</span>THL (Tech Hive Labs), Future Intelligence Ltd., Practin, INTEROPTICS, Hellenic Police, Center for Security Studies
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div >
                        <img src="assets/img/spain.svg" style={{border:'2px solid #292929',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{ marginLeft:'20px'}}>
                      <span style={{fontWeight:'600',color:'#ef8948'}}> Spain:</span> MION Technologies, Technical University of Catalonia, Public Security Institute of Catalonia
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                    <div >
                        <img src="assets/img/belgium.svg" style={{border:'2px solid #292929',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0"  style={{ marginLeft:'20px'}}>
                      <span style={{fontWeight:'600',color:'#ef8948'}}> Belgium:</span> M3 Systems 
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                    <div >
                        <img src="assets/img/romania.svg" style={{border:'2px solid #292929',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{ marginLeft:'20px'}}>
                      <span style={{fontWeight:'600',color:'#ef8948'}}> Romania:</span> Software Imagination & Vision
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                    <div>
                        <img src="assets/img/slovakia.svg" style={{border:'2px solid #292929',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{ marginLeft:'20px'}}>
                      <span style={{fontWeight:'600',color:'#ef8948'}}>Slovakia:</span> International Security and Emergency Management Institute
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                    <div >
                        <img src="assets/img/france.svg" style={{border:'2px solid #292929',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{ marginLeft:'20px'}}>
                      <span style={{fontWeight:'600',color:'#ef8948'}}>France:</span> Airbus, National School of Civil Aviation
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                    <div >
                        <img src="assets/img/armenia.svg" style={{border:'2px solid #292929',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{ marginLeft:'20px'}}>
                      <span style={{fontWeight:'600',color:'#ef8948'}}>Armenia:</span> Crisis Management State Academy
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                    <div >
                        <img src="assets/img/austria.svg" style={{border:'2px solid #292929',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{ marginLeft:'20px'}}>
                      <span style={{fontWeight:'600',color:'#ef8948'}}> Austria: </span> Johanniter Research and Innovation Center
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                    <div >
                        <img src="assets/img/malta.svg" style={{border:'2px solid #292929',borderRadius:'100px', width:'26px',top:'4px',position:'relative'}}></img>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0" style={{ marginLeft:'20px', color: ""}}>
                      <span style={{fontWeight:'600',color:'#ef8948'}}>Malta:</span> Epsilon  </p>
                    </div>
                  </div>
                </li>
              
              </ul>
            </div>
            <div className="col-md-6">
              <div className="about-content-right">
                <img
                  src="assets/img/img2.jpg"
                  width="500"
                  alt="about us"
                  style={{borderRadius:'20px'}}
                  className="img-fluid"
                />
              </div>
              
            </div>
          
         
          </div>
          
        </div>
      </section>
    </>
  );
}
